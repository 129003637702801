import { useMemo } from 'react';
import { useTranslation } from '~/shared/utils/translation';
import { useFrame } from '~/shared/utils';
import { ProductLabelSetting } from '~/lib';

type Product = {
    cust_isRemainder?: boolean;
    cust_testwinner?: string;
    cust_isNew?: boolean;
    _rankingInfo?: { promoted?: boolean };
    cust_sole_representation?: boolean;
    c_ItemSoleRepresentation?: boolean;
};

export const useProductLabels = (product: Product) => {
    const {
        cust_isRemainder,
        cust_testwinner,
        cust_isNew,
        _rankingInfo,
        cust_sole_representation,
        c_ItemSoleRepresentation,
    } = product;

    const isPromoted = _rankingInfo?.promoted ?? false;
    const isSoleRepresentation = cust_sole_representation || c_ItemSoleRepresentation || false;
    const { translate } = useTranslation();
    const { data: frame } = useFrame();

    const productLabelSettings = useMemo(
        () =>
            frame?.productSettings?.productLabels?.map((x) => {
                switch (x.identifier) {
                    case 'bestInTest':
                        return {
                            ...x,
                            labelText: translate('plp.product.bestInTest'),
                        };
                    case 'isNew':
                        return {
                            ...x,
                            labelText: translate('plp.product.isNew'),
                        };
                    case 'soleRepresentation':
                        return {
                            ...x,
                            labelText: translate('pcard.soleRepresentation'),
                        };
                    case 'remainder':
                        return {
                            ...x,
                            labelText: translate('pdp.remainder'),
                        };
                    case 'promoted':
                        return {
                            ...x,
                            labelText: translate('pcard.promoted'),
                        };
                    default:
                        return x;
                }
            }),
        [frame?.productSettings?.productLabels],
    );

    const labelsTopLeft = useMemo(() => {
        const labels = [] as ProductLabelSetting[];

        const handlePushLabel = (identifier: string) => {
            const matchingLabels =
                productLabelSettings?.filter((x) => x.identifier === identifier) || [];

            matchingLabels.forEach((label) => {
                if (!labels.some((x) => x.identifier === label.identifier)) {
                    labels.push(label);
                }
            });
        };

        if (cust_isRemainder) {
            handlePushLabel('remainder');
        }

        if (cust_testwinner) {
            handlePushLabel('bestInTest');
        }
        if (cust_isNew) {
            handlePushLabel('isNew');
        }
        if (isPromoted) {
            handlePushLabel('promoted');
        }

        return labels;
    }, []);

    const labelsTopRight = useMemo(
        () =>
            productLabelSettings?.filter(
                (x) => isSoleRepresentation && x.identifier === 'soleRepresentation',
            ) ?? [],
        [isSoleRepresentation, productLabelSettings],
    );
    return {
        labelsTopLeft,
        labelsTopRight,
    };
};
