import React, { ReactNode, useState } from 'react';
import { Link } from '$shared/components/Link/Link';
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';

const StyledLink = styled(Link)(() => ({}));

export const Hover = styled.div(
    ({ theme, isHovering }: { theme?: Theme; isHovering: boolean }) => ({
        position: 'relative',
        transform: `translateY(${isHovering ? '-2px' : 0})`,
        transition: 'transform 150ms ease-in',
        border: `1px solid ${theme?.colors.brownUltraLight}`,
        '& a:hover': {
            textDecoration: 'none',
        },
        height: '100%',
        width: '100%',
        '&:after': {
            transition: 'opacity 150ms ease-in',
            display: 'block',
            position: 'absolute',
            zIndex: -1,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            content: '""',
            width: '100%',
            height: '100%',
            opacity: isHovering ? 1 : 0,
            boxShadow: theme?.shadows.productCard,
        },

        [`${StyledLink}`]: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
        },
    }),
);

export const ProductCardLink = ({
    url,
    children,
    onClick,
    className,
}: {
    url?: string;
    className?: string;
    children: ReactNode;
    onClick?: () => void;
}) => {
    const [isMouseOver, setMouseOver] = useState(false);

    return (
        <Hover isHovering={isMouseOver} className={className}>
            <div
                style={{ position: 'relative', height: '100%' }}
                onMouseEnter={() => setMouseOver(true)}
                onMouseLeave={() => setMouseOver(false)}
            >
                {url ? (
                    <StyledLink
                        href={url}
                        onClick={onClick}
                        css={{ display: 'flex', flexDirection: 'column', height: '100%' }}
                    >
                        {children}
                    </StyledLink>
                ) : (
                    <div>{children}</div>
                )}
            </div>
        </Hover>
    );
};
